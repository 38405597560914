<template>
    <div>
      <!-- ALL ITEMS CARD -->
      <b-card no-body>
        <div class="m-2">
          <b-row>
            <b-col cols="12" md="6" offset-md="6">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  class="d-inline-block mr-1"
                  placeholder="Search By Name, Type"
                  v-model="keyword"
                  type="text"
                />
              </div>
            </b-col>
          </b-row>
        </div>
  
        <!-- ALl Item table -->
        <b-table
          id="NewProductListTableId"
          ref="refNewProductListTable"
          :items="allItems"
          responsive
          :fields="tableColumns"
          primary-key="prod_id"
          :per-page="pagination.perPage"
          :current-page="pagination.currentPage"
          :keyword="keyword"
        >
          <template #cell(index)="data">
            <span class="text-black">
              {{
                parseInt((pagination.currentPage - 1) * pagination.perPage) +
                data.index +
                1
              }}
            </span>
          </template>
  
          <template #cell(id)="data" class="text-black">
            <b-link
              :to="{
                name: `product-details`,
                query: { id: data.item.logs_old_data },
              }"
              class="font-weight-bold"
              @click="
                $router.push({
                  name: `product-details`,
                  query: { id: data.item.logs_old_data },
                })
              "
            >
              {{ data.item.logs_old_data }}
            </b-link>
          </template>
  
          <template #cell(name)="data">
            <b-media vertical-align="center">
              <span class="font-weight-bold d-block text-nowrap">
                {{
                  data.item.fk_user_id.user_fname +
                  " " +
                  data.item.fk_user_id.user_lname
                }}
              </span>
            </b-media>
          </template>
  
          <template #cell(email)="data">
            <b-media vertical-align="center">
              <span class="font-weight-bold d-block text-nowrap">
                {{ data.item.fk_user_id.user_email }}
              </span>
            </b-media>
          </template>
  
          <template #cell(user_type)="data">
            <div>
              {{
                data.item.fk_user_id.user_role.role_name
                  ? data.item.fk_user_id.user_role.role_name.toUpperCase()
                  : "N/A"
              }}
            </div>
          </template>

          <template #cell(message)="data">
            <div>
              {{
                data.item.logs_new_data
              }}
            </div>
          </template>
  
          <template #cell(date)="data">
            <div>
              {{
                data.item.logs_created_at
                  ? getHumanDate(data.item.logs_created_at)
                  : "N/A"
              }}
            </div>
          </template>
  
        </b-table>
  
        <b-pagination
          class="pl-2"
          aria-controls="NewProductListTableId"
          v-model="pagination.currentPage"
          :total-rows="allItems.length"
          :per-page="pagination.perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
  
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-card>
  
      
    </div>
  </template>
  
  <script>
  import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BCardText,
    BModal,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormSelect,
    BFormSelectOption,
  } from "bootstrap-vue";
  import vSelect from "vue-select";
  import Ripple from "vue-ripple-directive";
  import { onUnmounted } from "@vue/composition-api";
  import store from "@/store";
  import feather from "feather-icons";
  import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  import storageService from "@/apiServices/storageService";
  import ProductServices from "@/apiServices/ProductServices";
  
  import { refurbishStatus } from "../../SellerItems/refurbishStatusCode";
  import { sellStatus } from "../../SellerItems/sellStatusCode";
  
  import { avatarText } from "@core/utils/filter";
  import moment from "moment";
  import axios from "axios";
  
  export default {
    components: {
      BCardText,
      BCard,
      BRow,
      BCol,
      BFormInput,
      BButton,
      BTable,
      BMedia,
      BAvatar,
      BLink,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,
      BTooltip,
      BModal,
      BForm,
      BFormGroup,
      BFormTextarea,
      BFormCheckbox,
      BFormCheckboxGroup,
      BFormSelect,
      BFormSelectOption,
      feather,
      FeatherIcon,
      moment,
    },
    setup() {},
    beforeMount() {
      this.loadAllItems();
    },
    directives: {
      Ripple,
    },
    computed: {
      allItems() {
        return this.keyword
          ? this.allItemsData.filter((item) => {
              if (item.logs_created_by || item.logs_old_data) {
                if (
                  item.logs_old_data
                    .toString()
                    .toLowerCase()
                    .includes(this.keyword.toString().toLowerCase()) ||
                  item.logs_created_by
                    .toString()
                    .toLowerCase()
                    .includes(this.keyword.toString().toLowerCase()) ||
                  item.fk_user_id.user_fname
                    .toString()
                    .toLowerCase()
                    .includes(this.keyword.toString().toLowerCase()) ||
                  item.fk_user_id.user_lname
                    .toString()
                    .toLowerCase()
                    .includes(this.keyword.toString().toLowerCase())
                ) {
                  console.log("Searching => ", item);
                  return true;
                } else {
                  console.log("Saerch False =>", item);
                  return false;
                }
              }
            })
          : this.allItemsData;
      },
    },
    data() {
      return {
        sellStatus: sellStatus,
        refurbishStatus: refurbishStatus,
        sellStatusObj: {},
        refurbishStatusObj: {},
  
        sortBy: "",
        sortDesc: false,
        sortDirection: "asc",
        allItemsData: [],
        keyword: "",
        pagination: {
          currentPage: 1,
          perPage: 25,
          perPageOptions: [25, 50, 100],
        },
  
        tableColumns: [
          "index",
          { key: "id", label: "Item ID", sortable: true },
          { key: "name", label: "Name", sortable: true },
          { key: "email", label: "Email", sortable: true },
          { key: "user_type", label: "User Type", sortable: true },
          { key: "message", label: "Message", sortable: true },
          { key: "date", label: "Modified Date", sortable: true }
        ]

      };
    },
    methods: {
  
      loadAllItems() {
        ProductServices.getItemLogs()
          .then((response) => {
            this.allItemsData = response.data.data;
            console.log("getItemLogs: ", response.data.data);
          })
          .catch((error) => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
          });
      },
  
      getHumanDate: function (date) {
        return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
      },
  
      titleCase(str) {
        return str.replace(/\w\S*/g, function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
      }
  
    },
  };
  </script>
  
  <style>
  .table {
    color: #444 !important;
  }
  
  [dir] .table th,
  [dir] .table td {
    padding: 1.5rem;
  }
  
  [dir] .card {
    border-radius: 1rem !important;
  }
  
  /* [dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th, [dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th{
    background-color: white !important;
  } */
  
  [dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th {
    background-color: #ffffff00 !important;
  }
  
  [dir] .table thead th {
    border-bottom: 0px !important;
    /* text-shadow : 2px 1px 1px 2px #000000; */
  }
  
  th > div {
    /* text-shadow : 2px 1px 1px 2px #000000 !important;  */
    color: black !important;
    font-size: 16px !important;
    text-transform: capitalize !important;
  }
  </style>
  
  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  
  .invoice-filter-select {
    min-width: 190px;
  
    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }
  
    ::v-deep .vs__selected {
      width: 100px;
    }
  }
  
  .swal2-html-container {
    color: white;
  }
  </style>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  @import "@core/scss/vue/libs/vue-sweetalert.scss";
  </style>
  